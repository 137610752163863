import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../../../components/Layout/Layout';
import Seo from '../../../components/Seo/Seo';
import useIcons from '../../../hooks/useIcons';
import CollarLogo from '../../../images/collarLogo.svg';
import { clearLocalStorage, kebabCase } from '../../../utils';
import { BackImage } from '../../Providers/ProviderView/styled';
import { BranchItemContainer, BranchLink, BranchListItem, BranchLogo, BranchTitle, BranchesContainer, BranchesList, LogoContainer } from './styled';

export default function BranchesView({ pageContext }) {
  const { branches, provider } = pageContext;
  const icons = useIcons();
  const backIcon = getImage(icons?.arrowLeft)!;
  return (
    <Layout>
      {provider && (
        <BackImage to="../" onClick={() => clearLocalStorage()}>
          <GatsbyImage image={backIcon} alt={'Back Icon'} />
        </BackImage>
      )}
      {/* <BackImage to="../providers">
        <span>Switch to providers</span>
      </BackImage> */}
      <BranchesContainer>
        <BranchesList>
          {branches.map((branch: Record<string, any>) => {
            return (
              <BranchListItem key={branch.id}>
                <BranchItemContainer primaryColor={branch.colors.primary}>
                  <BranchLink to={kebabCase(branch.name)}>
                    <BranchLogo src={branch.photos.logo} alt={branch.name} />
                  </BranchLink>
                </BranchItemContainer>

                <BranchTitle>{branch.name}</BranchTitle>
              </BranchListItem>
            );
          })}
        </BranchesList>
        <LogoContainer>
          <Link to="https://getcollar.app/download" target="_blank" rel="noopener noreferrer">
            <CollarLogo />
          </Link>
        </LogoContainer>
      </BranchesContainer>
    </Layout>
  );
}

export const Head = ({ pageContext, data }) => {
  const branches = pageContext.branches;
  const randomBranch = branches[Math.floor(Math.random() * branches.length)];
  return <Seo title="Brands" description="Book services for your pet from our trusted brands." image={randomBranch?.photos?.logo} />;
};
